import React, { useRef, useState } from 'react';
import { BsTrash } from 'react-icons/bs';
import SignatureCanvas from 'react-signature-canvas';
import './SignaturePad.css'; // Import your CSS file for styling

const SignaturePad = () => {
  const signatureRef = useRef();
  const [penColor, setPenColor] = useState('black'); // Default pen color is black

  const clearSignature = () => {
    signatureRef.current.clear();
  };

  const saveSignature = () => {
    if (signatureRef.current.isEmpty()) {
      alert('Please provide a signature first.');
    } else {
      const signatureDataURL = signatureRef.current.toDataURL();
      const downloadLink = document.createElement('a');
      downloadLink.href = signatureDataURL;
      downloadLink.download = 'signature.png';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };



  

  const changePenColor = (color) => {
    setPenColor(color);
    signatureRef.current.penColor = color;
  };

  const copyToClipboard2 = async () => {
    if (signatureRef.current.isEmpty()) {
      alert('Please provide a signature first.');
      return;
    }
  
    const signatureDataURL = signatureRef.current.toDataURL();
    const response = await fetch(signatureDataURL);
    const blob = await response.blob();
  
    try {
      await navigator.clipboard.write([
        new ClipboardItem({
          [blob.type]: blob
        })
      ]);
      console.log('Signature copied to clipboard.');
    } catch (error) {
      console.error('Error copying to clipboard:', error);
    }
  };

  
  return (
    // Inside the return statement of SignaturePad component
<div className="signature-container">
  <div className="color-buttons">
  <button
      className={`color-button red ${penColor === 'red' ? 'selected' : ''}`}
      onClick={() => changePenColor('red')}
    >
      {penColor === 'red' && <span className="check-mark">&#10004;</span>}
    </button>
    <button
      className={`color-button blue ${penColor === 'blue' ? 'selected' : ''}`}
      onClick={() => changePenColor('blue')}
    >
      {penColor === 'blue' && <span className="check-mark">&#10004;</span>}
    </button>
    <button
      className={`color-button black ${penColor === 'black' ? 'selected' : ''}`}
      onClick={() => changePenColor('black')}
    >
      {penColor === 'black' && <span className="check-mark">&#10004;</span>}
    </button>
    <button className="clear-button" onClick={clearSignature}>
            <BsTrash />
          </button>
  </div>
  <SignatureCanvas
    ref={signatureRef}
    penColor={penColor}
	backgroundColor='rgb(255,255,255)'
    canvasProps={{ width: 600, height: 200, className: 'signature-canvas' }}
  />
  <div className="button-container">
    <button className="copy-button" onClick={copyToClipboard2}>Copy to Clipboard</button>
    <button className="save-button" onClick={saveSignature}>Save Signature</button>
  </div>
</div>
  );
};

export default SignaturePad;