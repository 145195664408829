import React from 'react';
import './App.css';
import SignaturePad from './SignaturePad';

function App() {
  return (
    <div className="App">
      <h1>Signature App v1</h1>
      <SignaturePad />
    </div>
  );
}

export default App;

